export const PUBLIC_COLLECTIONS_IMAGES_STEPHEN_HAWKING = [
  {
    title: 'Stephen William Hawking',
    filename: 'stephen-william-hawking-national-portrait-gallery-london.jpg',
    thumbnail: 'stephen-william-hawking-national-portrait-gallery-london_thumbnail.jpg',
    orientation: 'square',
    supplementary_info_prefix: '',
    supplementary_info: '24” x 24”',
    supplementary_info_suffix: 'National Portrait Gallery, London',
  },
]
